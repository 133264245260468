<template>
	<div>
		<b-container fluid class="shadow mb-5">
			<b-button
				style="box-shadow: none"
				:pressed="false"
				block
				align-h="center"
				size="sm"
				squared
				variant="danger"
				@click="csvExport(items)"
			>
				Export to CSV
			</b-button>
			<b-row class="mb-5">
				<b-input-group class="mb=5" label="Urls" label-for="urls">
					<b-form-textarea
						class="mb-3"
						style="box-shadow: none"
						id="urls"
						v-model="urlsString"
						placeholder="Enter URLs (New line separated) ..."
						rows="10"
						max-rows="50"
						size="sm"
						squared
						trim
						description="URLs to analyze"
						title="Enter URLs (New line separated)"
						:state="urlsString.length > 5"
						no-auto-shrink
					></b-form-textarea>
				</b-input-group>

				<b-button
					style="box-shadow: none"
					:pressed="false"
					block
					align-h="center"
					size="sm"
					squared
					variant="danger"
					@click="analyzeUrls()"
					>Analyze</b-button
				>
			</b-row>

			<!-- spinner -->
			<div class="text-center mb-5" v-if="isBusy && spinner">
				<b-row type="grow" class="justify-content-md-center my-5 pb-5">
					<b-spinner
						variant="dark"
						style="width: 25rem; height: 25rem"
					></b-spinner>
				</b-row>
				<span class="sr-only">Analyzing...</span>
			</div>
		</b-container>

		<!-- <b-container fluid v-if="!isBusy" class="shadow pb-3"> -->
		<b-container fluid class="shadow pb-3">
			<b-table
				id="urls-table"
				:busy.sync="isBusy"
				:items="items"
				:fields="itemsColumns"
				ref="itemsTable"
				stacked="md"
				show-empty
				small
				striped
				hover
				responsive
				dark
				caption-top
				style="font-size: 0.7rem"
			>
				<template #table-caption>URLs Content </template>

				<!-- <template #cell(tag)="row">
					<b style="background-color: #ffaaaa">{{ row.item.tag }}</b>
				</template> -->
			</b-table>
		</b-container>
		<b-container fluid v-if="!isBusy" class="shadow pb-3">
			<b-button
				style="box-shadow: none"
				:pressed="false"
				block
				align-h="center"
				size="sm"
				squared
				dark
				@click="downloadCsv()"
				>Download .csv</b-button
			>
		</b-container>
	</div>
</template>

<script>
import { MultiplePagesService } from "../services/multiple-pages/multiple-pages.service";
import { DownloadService } from "../services/download/download.service";
// import { TaxonomyService } from "../services/taxonomy/taxonomy.service";

export default {
	name: "MultiplePagesUrls",
	components: {},
	data() {
		return {
			isBusy: true,
			spinner: false,
			// urls: [],
			urlsString: "",
			items: [],
			itemsColumns: [],
		};
	},

	computed: {},

	async created() {},

	methods: {
		async analyzeUrls() {
			// if (this.urlsString) {
			this.isBusy = true;
			this.spinner = true;

			// this.urls = this.urlsString.split(/\r?\n/).map((url) => {
			//   return url.trim();
			// return url;
			// });

			const { data } = await MultiplePagesService.analyzeUrls({
				urlsString: this.urlsString,
			});

			this.items = data;
			// for (let i = 0; i < this.urls.length; i++) {
			//   const url = this.urls[i];
			//   const { data } = await TaxonomyService.runUrl({
			//     url: url.toLowerCase(),
			//     // badTags: this.badTags,
			//   });
			//   this.items.push({
			//     url: data[0].url,
			//     content: data[0].content,
			//   });
			// }

			// const { data } = await MultiplePagesService.analyzeUrls({
			//   urlsString: this.urlsString,
			// });
			// console.log("data: ", data);
			// this.items = data.categories.reduce((newArr, category) => {
			// 	if (category) {
			// 		newArr.push({
			// 			score: category.score,
			// 			category: category.label,
			// 		});
			// 	}
			// 	return newArr;
			// }, []);

			if (this.items[0]) {
				this.itemsColumns = Object.keys(this.items[0]);
			}
			this.isBusy = false;
			this.spinner = false;

			return;
			// }
		},
		async downloadCsv() {
			this.isBusy = true;
			try {
				DownloadService.downloadFile({
					name: "MultiplePagesUrls.csv",
				});
			} catch (error) {
				console.log(error);
			}
			this.isBusy = false;
		},

		async csvExport(arrData) {
			let csvContent = "data:text/csv;charset=utf-8,";
			csvContent += [
				Object.keys(arrData[0]).join(";"),
				...arrData.map((item) => Object.values(item).join(";")),
			]
				.join("\n")
				.replace(/(^\[)|(\]$)/gm, "");

			const data = encodeURI(csvContent);
			const link = document.createElement("a");
			link.setAttribute("href", data);
			link.setAttribute("download", "export.csv");
			link.click();
		},
	},
};
</script>