import { BaseService } from "../base.service";
import { ErrorWrapper, ResponseWrapper } from "../util";

export class MultiplePagesService extends BaseService {
    static get entity() {
        return "multiplepages"
    }

    static apiClient(options) {
        const baseURL = process.env.VUE_APP_API_URL;
        return this.initApiClient({ baseURL, ...options });
    }

    static async analyzeUrls(params = {}) {
        console.log(params)
        // params
        try {
            const response = await this.apiClient().get('multiple-pages/urls', { params });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

}